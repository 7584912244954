"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _answers = require("../actions/answers.actions");
var initialState = _immutable["default"].Map({
  answers: [],
  isLoading: false,
  isApiError: false,
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _answers.GET_ANSWERS_REQUEST:
      return state.set('answers', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _answers.GET_ANSWERS_SUCCESS:
      return state.set('answers', action.response).set('isLoading', false);
    case _answers.GET_ANSWERS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _answers.CREATE_ANSWER_REQUEST:
      return state.set('isLoading', true).set('isApiError', false);
    case _answers.CREATE_ANSWER_SUCCESS:
      var newAnswer = action.response;
      var answers = [].concat(state.get('answers'));
      answers.push(newAnswer);
      return state.set('answers', answers).set('isLoading', false);
    case _answers.CREATE_ANSWER_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _answers.SAVE_ANSWER_REQUEST:
      return state.set('isLoading', true).set('isApiError', false);
    case _answers.SAVE_ANSWER_SUCCESS:
      {
        // let answers = [...state.get('answers')];
        // let indexToEdit = findIndex(answers, { id: action.response.answerID });
        // if (indexToEdit > -1) {
        //   answers[indexToEdit].b = action.payload.message;
        //   answers[indexToEdit].e = true;
        //   return state.set('answers', answers).set('isLoading', false);
        // }
        return state.set('isLoading', false);
      }
    case _answers.SAVE_ANSWER_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _answers.DELETE_ANSWER_SUCCESS:
      {
        // let answers = [...state.get('answers')];
        // let indexToDelete = findIndex(answers, { id: action.response.answerId });
        // if (indexToDelete > -1) {
        //   answers.splice(indexToDelete, 1);
        // }
        return state.set('isLoading', false);
      }
    case _answers.DELETE_ANSWER_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false).set('isApiError', true);
    case _answers.DELETE_ANSWER_REQUEST:
      return state.set('errors', []).set('isLoading', true).set('isApiError', false);
    case _answers.UPLOAD_ANSWER_SUCCESS:
      {
        return state.set('answers', action.response).set('answerUploadComplete', true).set('isLoading', false);
      }
    case _answers.UPLOAD_ANSWER_FAILURE:
      return state.set('errors', action.messages).set('answerUploadComplete', true).set('isLoading', false).set('isApiError', true);
    case _answers.UPLOAD_ANSWER_REQUEST:
      return state.set('errors', []).set('answerUploadComplete', false).set('isApiError', false).set('isLoading', true);
    default:
      return state;
  }
};